.az-tiny-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.az-small-title {
  font-size: 2.2rem;
  font-weight: 800;

  // fix for anchor tags href links and the fixed header
  &[id]::before {
    content: '';
    display: block;
    height: 80px;
    margin-top: -80px;
    visibility: hidden;
  }
}

.az-large-title {
  font-size: 2.4rem;
  font-weight: 800;
}

.az-icon {
  path {
    stroke-width: 1.2;
  }
}

.az-text {
  line-height: 1.6rem !important;
}

.az-line-height {
  line-height: 1.6rem !important;
}

.az-contactLinks {
  color: #33a908;

  @media #{$sm-layout} {
    //font-size: 1rem !important;
  }
}

.az-about-titles {
  text-align: left;

  @media #{$sm-layout} {
    text-align: center;
  }

  @media #{$md-layout} {
    text-align: center;
  }
}


